import React from "react";
import Hero from "../../Components/Hero/Hero";
import Service from "../../Components/Services/Service";
const desc = `SSSPL offers a wide array of consultancy services across multiple
            sectors to enhance the Infrastructure of our country development.`;
const ServicePage = () => {
  return (
    <>
      <Hero
        title="Building A Better Future With Our Vision"
        para=""
        image="assets/images/services/services_bg.jpg"
        height="70vh"
      />
      <Service
        heading={"Our Services"}
        desc={desc}
        isRenderedInHomePage={false}
      />
    </>
  );
};

export default ServicePage;
