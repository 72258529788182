import React from "react";
import Hero from "./Hero/Hero";
import Project from "./Project/Project";
import { Link } from "react-router-dom";
import Contact from "./Contact/Contact";
import Sector from "./Sector/Sector";
import Partners from "./Partners";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import Service from "./Services/Service";
import WhoweAre from "./WhoweAre/WhoweAre";

const Template = () => {
  return (
    <>
      <div className="page-wrapper">
        <Hero
          title=" Welcome to Shashwat structural Solutions Private Limited"
          para="India’s leading organization in the world of Infrastructure Consultancy"
          image="assets/images/slider/slide-3.jpg"
          height="75vh"
        />
        {/* <!-- start preloader --> */}
        {/* <!-- end preloader --> */}
        {/* <!-- Start header --> */}
        {/* <!-- end of header --> */}
        {/* <!-- start of hero -->    */}
        {/* <!-- end of hero slider --> */}
        {/* <!-- start cta-section --> */}

        <section className="cta-section">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <div className="cta-text">
                  <h3>
                    We provide innovative Product Solutions for sustainable
                    progress.
                  </h3>
                  {/* <a href="#" className="theme-btn-s2">
                    Contact Us
                  </a> */}
                  <Link className="theme-btn-s2" to={"/contact"}>
                    {" "}
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end container --> */}
        </section>

        <WhoweAre />
        {/* <!-- end cta-section --> */}
        {/* <!-- start services-section --> */}

        <Sector heading={"Our Sector"} desc={""} isRenderedInHomePage={true} />

        <Service
          heading={"Industries Served"}
          desc={""}
          isRenderedInHomePage={true}
        />
        {/* <!-- end services-section --> */}
        {/* <!-- start about-section --> */}
        <section className="about-section">
          {/* <div className="content">
            <div className="left-col">
              <div className="inner">
                <h3>
                  We provide full range of Premium Industrial Services for your
                  business
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco.
                  Laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <div className="social">
                  <span>Get Connected With Us:</span>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin-square"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right-col">
              <div className="video-holder">
                <a
                  href="https://www.youtube.com/embed/7e90gBu4pas?autoplay=1"
                  className="video-btn"
                  data-type="iframe"
                >
                  <i className="fa fa-play"></i> Play Video
                </a>
              </div>
            </div>
          </div> */}
          {/* <!-- end content --> */}
        </section>
        {/* <!-- end about-section --> */}
        {/* <!-- start projects-section --> */}
        <Project />
        {/* <!-- end projects-section --> */}
        {/* <!-- start testimonials-section --> */}
        {/* <!-- end testimonials-section --> */}
        {/* <!-- start partners --> */}

        <Partners />
        {/* <!-- end partners --> */}
        {/* <!-- start contact-section --> */}
        <Contact />
        {/* <!-- end contact-section --> */}
        {/* <!-- start contact-info-map-section --> */}
        {/* <!-- end contact-info-map-section --> */}
        {/* <!-- start news-letter-section --> */}
        <section className="news-letter-section">
          {/* <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <h3>
                  Subscribe your E-mail for our{" "}
                  <span>Newsletter &amp; Business Tips</span>
                </h3>
              </div>
              <div className="col-md-6">
                <div className="newsletter-form">
                  <form>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="email address.."
                      />
                      <button type="submit">Subscribe</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> */}
          {/* <!-- end container --> */}
        </section>
        {/* <!-- end news-letter-section --> */}
        {/* <!-- start site-footer --> */}
        {/* <!-- end site-footer --> */}
      </div>
    </>
  );
};

export default Template;
