import React, { useState } from 'react'

const Project = ({place}) => {
    const [currentProjectCategory, setCurrentProjectCategory] =
      useState("all");

      const handleOnClink=(e,val)=>{
        e.preventDefault()
        setCurrentProjectCategory(val);
      }
  return (
    <>
      <section className="projects-section section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-lg-8 col-lg-offset-2 col-sm-10 col-sm-offset-1">
              <div className="section-title">
                <h2 className="text-primary-shashwat">
                  Our Prestigious Projects
                </h2>
                {/* <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col col-xs-12 sortable-gallery">
              <div className="gallery-filters">
                <ul>
                  <li>
                    <a
                      data-filter="*"
                      href=""
                      onClick={(e) => handleOnClink(e, "all")}
                      className={`${
                        currentProjectCategory === "all" ? "current" : ""
                      }`}
                    >
                      All
                    </a>
                  </li>
                  <li>
                    <a
                      data-filter=".construction"
                      onClick={(e) => handleOnClink(e, "construction")}
                      className={`${
                        currentProjectCategory === "construction"
                          ? "current"
                          : ""
                      }`}
                    >
                      Construction
                    </a>
                  </li>
                  <li>
                    <a
                      data-filter=".architectural"
                      onClick={(e) => handleOnClink(e, "architectural")}
                      className={`${
                        currentProjectCategory === "architectural"
                          ? "current"
                          : ""
                      }`}
                    >
                      Architectural
                    </a>
                  </li>
                  <li>
                    <a
                      data-filter=".mechanical"
                      onClick={(e) => handleOnClink(e, "mechanical")}
                      className={`${
                        currentProjectCategory === "mechanical" ? "current" : ""
                      }`}
                    >
                      Mechanical
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      data-filter=".welding"
                      onClick={(e) => handleOnClink(e, "welding")}
                      className={`${
                        currentProjectCategory === "welding" ? "current" : ""
                      }`}
                    >
                      Welding
                    </a>
                  </li>
                </ul>
              </div>

              <div className="gallery-container">
                <div
                  className="box construction"
                  style={{
                    display: `${
                      ["construction", "all"].includes(currentProjectCategory)
                        ? ""
                        : "none"
                    }`,
                  }}
                >
                  <img src="assets/images/projects/img-1.jpg" alt />
                  <div className="details">
                    <div className="info">
                      <span className="cat">Melbourne, AU</span>
                      <h3>
                        <a href="#">Mechanical Works</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div
                  className="box construction architectural"
                  style={{
                    display: `${
                      ["construction", "architectural", "all"].includes(
                        currentProjectCategory
                      )
                        ? ""
                        : "none"
                    }`,
                  }}
                >
                  <img src="assets/images/projects/img-2.jpg" alt />
                  <div className="details">
                    <div className="info">
                      <span className="cat">Melbourne, AU</span>
                      <h3>
                        <a href="#">Architectural Works</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div
                  className="box plant construction"
                  style={{
                    display: `${
                      ["construction", "all"].includes(currentProjectCategory)
                        ? ""
                        : "none"
                    }`,
                  }}
                >
                  <img src="assets/images/projects/img-3.jpg" alt />
                  <div className="details">
                    <div className="info">
                      <span className="cat">Melbourne, AU</span>
                      <h3>
                        <a href="#">Mechanical Works</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div
                  className="box construction mechanical"
                  style={{
                    display: `${
                      ["mechanical", "construction", "all"].includes(
                        currentProjectCategory
                      )
                        ? ""
                        : "none"
                    }`,
                  }}
                >
                  <img src="assets/images/projects/img-4.jpg" alt />
                  <div className="details">
                    <div className="info">
                      <span className="cat">Melbourne, AU</span>
                      <h3>
                        <a href="#">Mechanical Works</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div
                  className="box plant welding"
                  style={{
                    display: `${
                      ["welding", "all"].includes(currentProjectCategory)
                        ? ""
                        : "none"
                    }`,
                  }}
                >
                  <img src="assets/images/projects/img-5.jpg" alt />
                  <div className="details">
                    <div className="info">
                      <span className="cat">Melbourne, AU</span>
                      <h3>
                        <a href="#">Mechanical Works</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div
                  className="box mechanical"
                  style={{
                    display: `${
                      ["mechanical", "all"].includes(currentProjectCategory)
                        ? ""
                        : "none"
                    }`,
                  }}
                >
                  <img src="assets/images/projects/img-6.jpg" alt />
                  <div className="details">
                    <div className="info">
                      <span className="cat">Melbourne, AU</span>
                      <h3>Mechanical Works</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="box construction"
                  style={{
                    display: `${
                      ["construction", "all"].includes(currentProjectCategory)
                        ? ""
                        : "none"
                    }`,
                  }}
                >
                  <img src="assets/images/projects/img-7.jpg" alt />
                  <div className="details">
                    <div className="info">
                      <span className="cat">Melbourne, AU</span>
                      <h3>Mechanical Works</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="box plant welding"
                  style={{
                    display: `${
                      ["welding", "all"].includes(currentProjectCategory)
                        ? ""
                        : "none"
                    }`,
                  }}
                >
                  <img src="assets/images/projects/img-8.jpg" alt />
                  <div className="details">
                    <div className="info">
                      <span className="cat">Melbourne, AU</span>
                      <h3>Mechanical Works</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Project