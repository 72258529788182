import React from 'react'
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      {/* <!-- start site-footer --> */}
      <footer className="site-footer">
        <div className="upper-footer">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-sm-6">
                <div className="widget about-widget">
                  <div className="footer-logo">
                    {/* <img src="assets/images/footer-logo.png" alt /> */}
                    <img
                      src="assets/images/Shashawat_Logo.png"
                      style={{ width: "50px", height: "50px" }}
                      alt
                    />
                  </div>
                  <p>
                    Ground Floor, 65A, Plot No. 65,
                    <br />
                    Gali No. 4, Kundan Nagar,
                    <br />
                    Laxmi Nagar, New Delhi 110 092
                  </p>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-google-plus"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-rss"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col col-md-6 col-sm-6">
                <div className="widget services-widget">
                  <h3 className="text-primary-shashwat">Menu</h3>
                  <ul>
                    <li>
                      <NavLink className="fa fa-angle-right" to={"/"}>
                        {" "}
                        Home{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="fa fa-angle-right" to={"/about"}>
                        {" "}
                        About us{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="fa fa-angle-right" to={"/sector"}>
                        {" "}
                        Sector{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="fa fa-angle-right" to={"/service"}>
                        {" "}
                        Service{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="fa fa-angle-right" to={"/contact"}>
                        {" "}
                        Contact{" "}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end container --> */}
        </div>
        {/* <!-- end upper-footer --> */}
        <div className="copyright-info">
          <div className="container">
            <p>
              2024 &copy; All Rights Reserved by{" "}
              <a href="http://templatespoint.net">
                Shashwat structural Solutions Private Limited
              </a>
            </p>
          </div>
        </div>
      </footer>
      {/* <!-- end site-footer --> */}
    </>
  );
}

export default Footer