import React from 'react'
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <>
      <div className="container-fluid-not" style={{ width: "100%" }}>
        <div class="site-mobile-menu site-navbar-target">
          <div class="site-mobile-menu-header">
            <div class="site-mobile-menu-close mt-3">
              <span class="icon-close2 js-menu-toggle"></span>
            </div>
          </div>
          <div class="site-mobile-menu-body"></div>
        </div>

        <div class="top-bar">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <a href="mailto:shashwat.structural@mailssspl.com" class="mr-2">
                  <span class="mr-2  icon-envelope-open-o"></span>{" "}
                  <span class="d-none d-md-inline-block">
                    {/* info@yourdomain.com */}
                    shashwat.structural@mailssspl.com
                  </span>
                </a>

                <a href="tel:+919830035864" class="">
                  <span class="mr-1  icon-phone"></span>
                  +91-98 300 35 864
                </a>

                <div class="float-right">
                  <a href="#" class="">
                    <span class="mr-2  icon-twitter"></span>{" "}
                    <span class="d-none d-md-inline-block">Twitter</span>
                  </a>
                  <span class="mx-md-2 d-inline-block"></span>
                  <a href="#" class="">
                    <span class="mr-2  icon-facebook"></span>{" "}
                    <span class="d-none d-md-inline-block">Facebook</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <header
          class="site-navbar js-sticky-header site-navbar-target"
          role="banner"
        >
          <div class="container">
            <div class="row align-items-center position-relative">
              <div class="site-logo">
                <NavLink to={"/"} className="nav-link">
                  <img
                    src="assets/images/Shashawat_Logo.png"
                    style={{ width: "50px", height: "50px" }}
                    alt
                  />
                </NavLink>
              </div>

              <div class="col-12">
                <nav
                  class="site-navigation text-right ml-auto "
                  role="navigation"
                >
                  <ul class="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
                    {/* <li>
                      <NavLink to={"/"} className="nav-link custom-navlink">
                        Home
                      </NavLink>
                    </li> */}

                    {/* <li class="has-children">
                      <a href="#about-section" class="nav-link">
                        About Us
                      </a>
                      <ul class="dropdown arrow-top">
                        <li>
                          <a href="#team-section" class="nav-link">
                            Team
                          </a>
                        </li>
                        <li>
                          <a href="#pricing-section" class="nav-link">
                            Pricing
                          </a>
                        </li>
                        <li>
                          <a href="#faq-section" class="nav-link">
                            FAQ
                          </a>
                        </li>
                        <li class="has-children">
                          <a href="#">More Links</a>
                          <ul class="dropdown">
                            <li>
                              <a href="#">Menu One</a>
                            </li>
                            <li>
                              <a href="#">Menu Two</a>
                            </li>
                            <li>
                              <a href="#">Menu Three</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      {/* <a href="#about-section" class="nav-link">
                        About Us
                      </a> */}
                      <NavLink to={"/about"} className="nav-link">
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      {/* <a href="#sector-section" class="nav-link">
                        Sectors
                      </a> */}
                      <NavLink to={"/sector"} className="nav-link">
                        Sectors
                      </NavLink>
                    </li>
                    <li>
                      {/* <a href="#services-section" class="nav-link">
                        Services
                      </a> */}
                      <NavLink to={"/service"} className="nav-link">
                        Services
                      </NavLink>
                    </li>

                    <li>
                      {/* <a href="#projects-section" class="nav-link">
                        Projects
                      </a> */}
                      <NavLink to={"/project"} className="nav-link">
                        Projects
                      </NavLink>
                    </li>

                    {/* <li>
                      <a href="#why-us-section" class="nav-link">
                        Why Us
                      </a>
                    </li> */}

                    {/* <li>
                      <a href="#testimonials-section" class="nav-link">
                        Testimonials
                      </a>
                    </li> */}
                    {/* <li>
                      <a href="#blog-section" class="nav-link">
                        Blog
                      </a>
                    </li> */}
                    <li>
                      {/* <a href="#contact-section" class="nav-link">
                        Contact
                      </a> */}
                      <NavLink to={"/contact"} className="nav-link">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>

              <div class="toggle-button d-inline-block d-lg-none">
                <a
                  href="#"
                  class="site-menu-toggle py-5 js-menu-toggle text-black"
                >
                  <span class="icon-menu h3"></span>
                </a>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default Header