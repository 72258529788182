import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    // height: 250,
    height: "auto",
  },
  tabs: {
    borderRight: `1px solid #046380`,
    // borderRight: `1px solid red`,
    overflow: "visible",
  },
  tabpanel: {
    textAlign: "center",
  },
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Our Vision" {...a11yProps(0)} />
        <Tab label="Our Mission" {...a11yProps(1)} />
        <Tab label="Overview" {...a11yProps(2)} />
        <Tab label="What we do" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={value} index={0} sx={{ textAlign: "center" }}>
        At our company, we are committed to driving sustainable infrastructure
        growth in our country, contributing to nation-building. Our focus on
        sustainable infrastructure development aligns with our belief that
        infrastructure is a critical driver of economic growth and social
        development. By promoting sustainable infrastructure practices, we aim
        to minimize environmental impact, reduce operating costs, and enhance
        overall infrastructure performance. Our efforts in this area will help
        create a more sustainable and prosperous future for our country,
        benefiting generations to come. Join us in our mission to lead the
        charge towards a more sustainable and prosperous nation
      </TabPanel>
      <TabPanel value={value} index={1}>
        At our engineering consulting firm, we strive to be industry leaders by
        offering exceptional Technical Advisory Services to top construction
        companies through strategic partnerships. Our solutions are renowned for
        their world-class quality, and we pride ourselves on delivering
        innovative and effective engineering solutions that meet the highest
        standards of excellence. By collaborating with the best in the
        construction industry, we are able to leverage our expertise and
        resources to provide our clients with unparalleled service and support.
        Join us in our mission to revolutionize the engineering consulting
        landscape through our cutting-edge solutions and unmatched partnerships.
      </TabPanel>
      <TabPanel value={value} index={2}>
        SSSPL is a pioneer infrastructure consultancy firm with expertise in
        Detailed Engineering Design and Pre-bid consultancy for EPC Contractor
        and HAM and BOT operator. The team at SSSPL, formerly associated with
        L&W Infrastructures Pvt. Ltd., consists of over 50 professional staff
        with knowledge of international standards. The company is committed to
        quality, client satisfaction, and professionalism in infrastructure
        development.
      </TabPanel>
      <TabPanel value={value} index={3}>
        SSSPL is dedicated to delivering high-quality services in the field of
        infrastructure development, collaborating with leading construction
        companies and maintaining a strong commitment to ethical standards and
        client satisfaction. At SSSPL, we are constantly striving towards
        achieving the highest standards while maintaining the quality.
        Meticulous Planning in every field and attention to minute details are
        elements that SSSPL employs, together with a proper blend of resources
        and efficiency at every stage of the project implementation. The aim is
        to ensure that the projects undertaken by us are cost effective; time
        bound and utilizes efficiently and scientifically local talent and
        resources.
      </TabPanel>
    </div>
  );
}
