import React from 'react'
import Contact from '../../Components/Contact/Contact'
import Hero from '../../Components/Hero/Hero'

const ContactPage = () => {
  return (
    <>
      {/* <Hero
        title="Building A Better Future With Our Vision"
        para=""
        image="assets/images/slider/slide-2.jpg"
        height="70vh"
        hidden="true"
      /> */}
      <Contact />
    </>
  );
}

export default ContactPage