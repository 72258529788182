import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Carousel from './Components/Carousel';

import HomeTemplate from './Components/Teamplate';

function App() {
  return (
    <>
      {/* <Header /> */}
      <Carousel />
      {/* <HomeTemplate/> */}
    </>
  );
}

export default App;
