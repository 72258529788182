import React from 'react'
import Sector from './../../Components/Sector/Sector';
import Hero from '../../Components/Hero/Hero';
const desc = `SSSPL has built its credibility through hard work and dedication.
            The services we provide cater to a wide variety of sectors that we
            are constantly striving hard to keep adding in our portfolio.`;
const heading = "Our Sectors";
const SectorPage = () => {
  return (
    <>
      <Hero
        title="Building A Better Future With Our Vision"
        para=""
        image="assets/images/slider/slide-2.jpg"
        height="70vh"
      />
      <Sector heading={heading} desc={desc} isRenderedInHomePage={false} />
    </>
  );
}

export default SectorPage;