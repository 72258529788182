import React from 'react'

const Partners = () => {
  return (
    <>
      {/* <!-- start partners --> */}
      <section className="partners-section">
        <h2 className="text-align-center mb-5 text-primary-shashwat">
        Our  Partners
        </h2>
        <div className="container">
          <div className="row">
            {/* <div className="col col-xs-12">
                <div className="partners-slider">
                  <div className="grid">
                    <img src="assets/images/partners/img-1.png" alt />
                  </div>
                  <div className="grid">
                    <img src="assets/images/partners/img-2.png" alt />
                  </div>
                  <div className="grid">
                    <img src="assets/images/partners/img-3.png" alt />
                  </div>
                  <div className="grid">
                    <img src="assets/images/partners/img-4.png" alt />
                  </div>
                  <div className="grid">
                    <img src="assets/images/partners/img-2.png" alt />
                  </div>
                </div>
              </div> */}
            {/* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */}
            <div
              className="our-partner-column col-md-2"
            >
              <img src="assets/images/partners/img-1.png" alt />
            </div>
            <div
              className="our-partner-column col-md-2"
              // style={{
              //   width: "95%",
              //   boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              //   border: "1px solid red",
              //   display: "flex",
              //   justifyContent: "center",
              //   padding: "15px 0px",
              //   margin: "40px auto",
              // }}
            >
              <img src="assets/images/partners/img-2.png"  alt />
            </div>
            <div  className="our-partner-column col-md-2">
              <img src="assets/images/partners/img-3.png" alt />
            </div>
            <div  className="our-partner-column col-md-2">
              <img src="assets/images/partners/img-4.png" alt />
            </div>
            <div  className="our-partner-column col-md-2">
              <img src="assets/images/partners/img-2.png" alt />
            </div>
            <div  className="our-partner-column col-md-2">
              <img src="assets/images/partners/img-3.png" alt />
            </div>
          </div>
          {/* <!-- end row --> */}
        </div>
        {/* <!-- end container --> */}
      </section>
      {/* <!-- end partners --> */}
    </>
  );
}

export default Partners