import React from 'react'
import Hero from '../../Components/Hero/Hero';
import Project from '../../Components/Project/Project';

const ProjectPage = () => {
  return (
    <>
      <Hero
        title="Building A Better Future With Our Vision"
        para=""
        image="assets/images/slider/slide-2.jpg"
        height="70vh"
      />
      <Project />
    </>
  );
}

export default ProjectPage