import React from 'react'
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Stack } from '@mui/material';
import { Typography } from '@material-ui/core';
const OurPresence = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          padding: "0px 20px",
          alignItems: "center",
          marginTop: "20px",
          backgroundColor: "#FFF",
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
          style={{ margin: "0px auto",}}
        >
          <Typography
            style={{ margin: "0px auto" }}
            variant="h3"
            component="h3"
            className="text-primary-shashwat"
          >
            Our Presence
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={11}
            sm={11}
            md={5}
            sx={{ color: "white",}}
            className="text-primary-shashwat"
          >
            SSSPL has a dedicated workforce of 300+ professionals having
            national & international expertise in various infrastructure
            disciplines. The teams are placed in more than 15 offices across
            India. These offices are equipped with the highest quality of
            state-of-the-art machinery and up to date with the latest
            technologies. Our Corporate Office is situated in Noida while our
            Regional Offices are operated in Ranchi, Patna, Bhubaneshwar,
            Hyderabad & Kolkata. Apart from this, we also have offices at all of
            our project sites that remain operational throughout the tenure of
            the project – from start till the end of the project.
          </Grid>
          <Grid item xs={11} sm={11} md={5} >
            <img
              // srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
              src={`https://chaitanyaprojects.com/img/Group%2039114.svg`}
              alt={"img"}
              loading="lazy"
            />
          </Grid>
        </Stack>
      </Grid>
    </>
  );
}

export default OurPresence