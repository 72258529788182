import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import HomeTemplate from './Components/Teamplate';
import Layout from './Layout/Layout';
import About from './Components/About';
import Template from './Components/Teamplate';
import JustifiedExample from './Components/Tabs/Tabs';
import UncontrolledExample from './Components/Tabs/Tabs';
import VerticalTabs from './Components/Tabs/Tabs';

import "./index.css"
import SectorPage from './Pages/Sector/Sector';
import ServicePage from './Pages/Service/ServicePage';
import ProjectPage from './Pages/Project/ProjectPage';
import ContactPage from './Pages/Contact/ContactPage';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Template />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "tabs",
        element: <VerticalTabs />,
      },
      {
        path: "sector",
        element: <SectorPage />,
      },
      {
        path: "service",
        element: <ServicePage />,
      },
      {
        path: "project",
        element: <ProjectPage />,
      },
      {
        path: "/contact",
        element: <ContactPage/>,
      },
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
