import React from "react";
import "./hero.css"

const Hero = ({para,title,image,height}) => {
  return (
    <>
      <div
        className="hero-container"
        style={{ backgroundImage: `url(${image})`,backgroundRepeat:"no-repeat", backgroundSize:"cover" ,height: "auto" }}
      >
        <div className="hero-content-container">
          <div>
            <h2 className="text-white text-align-center">{title}</h2>
            <h4 className="text-white text-align-center">{para}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
