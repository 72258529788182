import React from 'react'
import Hero from './Hero/Hero';
import Tabs from './Tabs/Tabs';
// import BasicTabs from './Tabs/Tabs';
import VerticalTabs from './Tabs/Tabs';
import OurPresence from './OurPresence';

const About = () => {
  return (
    <>
      <Hero
        title="Shashwat structural Solutions Private Limited"
        para="Helping You Build A Better World"
        image="assets/images/slider/slide-2.jpg"
        height="70vh"
      />
      <h2
        style={{ textAlign: "center", margin: "50px 0px" }}
        className="text-primary-shashwat"
      >
        About us
      </h2>
      {/* <BasicTabs /> */}
      <VerticalTabs />

      <OurPresence />
    </>
  );
}

export default About