import React from 'react'

const Contact = () => {
  return (
    <>
      <section className="contact-section section-padding">
        <div className="container">
          <div className="col col-lg-11">
            <div className="row">
              <div className=" col-lg-5 col-md-5">
                <div className="section-title-s3">
                  <h2>Request a Quote</h2>
                </div>
              </div>
              <div className="col col-lg-7 col-md-7">
                <div className="title-text">
                  <p>
                    We will respond to your queries at the earliest possible.
                  </p>
                </div>
              </div>
            </div>

            <div className="contact-form form">
              <form
                method="post"
                id="contact-form"
                className="contact-validation-active"
              >
                <div>
                  <label for="name">Full Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                  />
                </div>
                <div>
                  <label for="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                  />
                </div>
                <div>
                  <label for="phone">Phone Number</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="form-control"
                  />
                </div>
                <div>
                  {/* <label>Business Type</label>
                          <select name="select" className="form-control">
                              <option selected disabled> -- Select One -- </option>
                              <option value="Select One">Select One</option>
                              <option value="Select Two">Select Two</option>
                              <option value="Select Three">Select Three</option>
                          </select> */}
                </div>
                <div className="submit-btn-wrap">
                  <input value="Submit" type="submit" />
                  <div id="loader">
                    <i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                  </div>
                </div>
                <div className="error-handling-messages">
                  <div id="success">Thank you</div>
                  <div id="error">
                    {" "}
                    Error occurred while sending email. Please try again later.{" "}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- end container --> */}
        <div className="contact-man">
          <img src="assets/images/contact-man.png" alt />
        </div>
      </section>
      <section className="contact-info-map-section">
        <div className="content">
          <div className="left-col">
            <div className="location-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.227813201965!2d77.358725175008!3d28.622933584517423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5467381a0c7%3A0x55cf99202a8c31!2sOkaya%20Centre!5e0!3m2!1sen!2sin!4v1703678958420!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="right-col">
            <div className="inner">
              <div className="title-text">
                <h3>Drop us a line</h3>
                <p>For any kind of query, contact us with the details below.</p>
              </div>
              <div className="contact-info">
                <ul>
                  <li>
                    <a href="tel:+919830035864">
                      <i className="fa fa-phone"></i> +91- 98 300 35 864
                    </a>
                  </li>
                  <li>
                    <a href="mailto:shashwat.structural@mailssspl.com">
                      <i className="fa fa-envelope"></i>{" "}
                      shashwat.structural@mailssspl.com
                    </a>
                  </li>
                  <li>
                    <i className="fa fa-home"></i>Ground Floor, 65A, Plot No.
                    65, Gali No. 4, Kundan Nagar, Laxmi Nagar, New Delhi 110 092
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6"></div>
        </div>
        {/* <!-- end content --> */}
      </section>
    </>
  );
}

export default Contact