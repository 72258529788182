import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/material";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
const Sector = ({ heading, desc, isRenderedInHomePage }) => {
  const AllSector = (
    <Grid container spacing={0} gap={1} justifyContent={"center"}>
      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_1.jpg"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Architectural & Allied Services
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_2.jpg"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Institutional Strengthening & Capacity Building
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_3.jpg"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Urban & Regional Infrastructure Development
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_4.jpg"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Water Resources Development & Management
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_5.jpg"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Socio-Economic & Environmental Impact Assessment
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_6.jpg"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Traffic & Transportation
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_7.jpg"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Highway & Roads
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_8.png"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Bridges & Structures
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
  const SectorForHome = (
    <Grid container spacing={0} gap={1} justifyContent={"center"}>
      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_1.jpg"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Architecture Design
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_2.jpg"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Bridge Rehabilitation
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
        <Card style={{ height: "235px" }}>
          <CardMedia
            sx={{ height: "65%" }}
            image="assets/images/sector/sector_3.jpg"
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="p" component="b">
              Structural Design
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={9.1} margin="30px 0px 0px 0px">
        <p className="view-all-services">
          {" "}
          Click here to view{" "}
          <Link to={"/sector"}>
            {" "}
            <b>
              All Sector <i className="fa fa-long-arrow-right"></i>
            </b>
          </Link>
        </p>
      </Grid>
    </Grid>
  );
  return (
    <Grid
      container
      spacing={0}
      sx={{
        width: {
          xs: "96%",
          sm: "97%",
          md: "90%",
        },
        margin: "30px auto",
      }}
      //   gap={2}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        style={{ margin: "0px auto" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          justifyContent={"center"}
        >
          <h2 className="text-primary-shashwat" style={{ textAlign: "center" }}>
            {heading}
          </h2>
        </Grid>
        <Grid item xs={11} md={8} justifyContent={"center"}>
          <h5 style={{ color: "black", textAlign: "center" }}>{desc}</h5>
        </Grid>
      </Stack>

      {/* <Grid container spacing={0} gap={1} justifyContent={"center"}>
        <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
          <Card style={{ height: "235px" }}>
            <CardMedia
              sx={{ height: "65%" }}
              image="assets/images/sector/sector_1.jpg"
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="p" component="b">
                Architectural & Allied Services
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
          <Card style={{ height: "235px" }}>
            <CardMedia
              sx={{ height: "65%" }}
              image="assets/images/sector/sector_2.jpg"
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="p" component="b">
                Institutional Strengthening & Capacity Building
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
          <Card style={{ height: "235px" }}>
            <CardMedia
              sx={{ height: "65%" }}
              image="assets/images/sector/sector_3.jpg"
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="p" component="b">
                Urban & Regional Infrastructure Development
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
          <Card style={{ height: "235px" }}>
            <CardMedia
              sx={{ height: "65%" }}
              image="assets/images/sector/sector_4.jpg"
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="p" component="b">
                Water Resources Development & Management
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
          <Card style={{ height: "235px" }}>
            <CardMedia
              sx={{ height: "65%" }}
              image="assets/images/sector/sector_5.jpg"
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="p" component="b">
                Socio-Economic & Environmental Impact Assessment
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
          <Card style={{ height: "235px" }}>
            <CardMedia
              sx={{ height: "65%" }}
              image="assets/images/sector/sector_6.jpg"
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="p" component="b">
                Traffic & Transportation
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
          <Card style={{ height: "235px" }}>
            <CardMedia
              sx={{ height: "65%" }}
              image="assets/images/sector/sector_7.jpg"
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="p" component="b">
                Highway & Roads
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
          <Card style={{ height: "235px" }}>
            <CardMedia
              sx={{ height: "65%" }}
              image="assets/images/sector/sector_8.png"
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="p" component="b">
                Bridges & Structures
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}

      {isRenderedInHomePage ? SectorForHome : AllSector}
    </Grid>
  );
};

export default Sector;
