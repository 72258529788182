import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/material";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
const servicesData = [
  {
    name: "Structural Design",
    image: "assets/images/services/services_13.jpg",
    imageTitle: "green",
  },
  {
    name: " Structural Health Monitoring",
    image: "assets/images/services/services_6.jpg",
    imageTitle: "green",
  },
  {
    name: "Bridge Rehabilitation",
    image: "assets/images/services/services_8.jpg",
    imageTitle: "green",
  },
  {
    name: " Master Planning & Urban Planning",
    image: "assets/images/services/services_11.jpg",
    imageTitle: "green",
  },
  {
    name: "Project Management Consultancy",
    image: "assets/images/services/services_10.jpg",
    imageTitle: "green",
  },
  {
    name: "Lender’s Independent Engineer (LIE)",
    image: "assets/images/services/services_7.jpg",
    imageTitle: "green",
  },
  {
    name: "Feasibility Studies",
    image: "assets/images/services/services_9.jpg",
    imageTitle: "green",
  },
  {
    name: " Transaction Advisory for Large Infrastructure Projects",
    image: "assets/images/services/services_5.jpg",
    imageTitle: "green",
  },
  {
    name: "Techno-Economic & Financial Analysis",
    image: "assets/images/services/services_3.jpg",
    imageTitle: "green",
  },
  {
    name: "Detailed Engineering Design",
    image: "assets/images/services/services_2.jpg",
    imageTitle: "green iguana",
  },
  {
    name: "Prebid Consultancy Services",
    image: "assets/images/services/prebid_consultancy.jpg",
    imageTitle: "green iguana",
  },
  {
    name: "Transportation Planning",
    image: "assets/images/services/services_4.jpg",
    imageTitle: "green",
  },
  {
    name: "Architecture Design",
    image: "assets/images/services/img-1.jpg",
    imageTitle: "green iguana",
  },

  {
    name: "Volumetric Modular Building Design",
    image: "assets/images/services/img-4.jpg",
    imageTitle: "green",
  },
  {
    name: " Bio Medical Planning & Engineering",
    image: "assets/images/services/img-5.jpg",
    imageTitle: "green",
  },
  {
    name: "Interior Design",
    image: "assets/images/services/services_17.jpg",
    imageTitle: "green",
  },
  {
    name: "Landscape Design",
    image: "assets/images/services/services_15.jpg",
    imageTitle: "green",
  },
  {
    name: "Mechanical ,Electrical, Plumbing Design",
    image: "assets/images/services/services_14.jpg",
    imageTitle: "green",
  },

  {
    name: "  Preparation of Detailed Project Reports",
    image: "assets/images/services/services_1.jpg",
    imageTitle: "green iguana",
  },
];
const Service = ({ heading, desc, isRenderedInHomePage }) => {

  const AllSector = (
    <Grid container spacing={0} gap={1} justifyContent={"center"}>
      {servicesData.map(({ name, image }) => (
        <Grid item xs={5} sm={4} md={2} margin="30px 0px 0px 0px">
          <Card style={{ height: "235px" }}>
            <CardMedia
              sx={{ height: "65%" }}
              image={image}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="p" component="b">
                {name}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}

     
    </Grid>
  );
  
const SectorForHome = (
  <Grid container spacing={0} gap={1} justifyContent={"center"}>
    <Grid item xs={12} md={3} margin="30px 0px 0px 0px" >
      <Card style={{ height: "235px" }} >
        <CardMedia
          sx={{ height: "65%" }}
          image="assets/images/services/services_12.jpg"
          title="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="p" component="b">
            Inventorisation of Highway Assets
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
      <Card style={{ height: "235px" }}>
        <CardMedia
          sx={{ height: "65%" }}
          image="assets/images/services/services_1.jpg"
          title="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="p" component="b">
            Preparation of Detailed Project Reports
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} md={3} margin="30px 0px 0px 0px">
      <Card style={{ height: "235px" }}>
        <CardMedia
          sx={{ height: "65%" }}
          image="assets/images/services/services_13.jpg"
          title="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="p" component="b">
            Structural Design
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={9.1} margin="30px 0px 0px 0px">
      <p className="view-all-services">
        {" "}
        Click here to view{" "}
        <Link to={"/service"}>
          {" "}
          <b>
            All Services <i className="fa fa-long-arrow-right"></i>
          </b>
        </Link>
      </p>
    </Grid>
  </Grid>
);

  return (
    <Grid
      container
      spacing={0}
      sx={{
        width: {
          xs: "96%",
          sm: "97%",
          md: "90%",
        },
        margin: "30px auto",
      }}
      //   gap={2}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        style={{ margin: "0px auto" }}
      >
        <Grid item xs={12} md={12} justifyContent={"center"}>
          <h2 className="text-primary-shashwat" style={{ textAlign: "center" }}>
            {heading}
          </h2>
        </Grid>
        <Grid item xs={11} md={8} justifyContent={"center"}>
          <h5 style={{ color: "black", textAlign: "center" }}>
           {desc}
          </h5>
        </Grid>
      </Stack>
      {isRenderedInHomePage ? SectorForHome : AllSector}
    </Grid>
  );
};

export default Service;
