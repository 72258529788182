import React from 'react'
import { Link } from 'react-router-dom';

const WhoweAre = () => {
  return (
    <>
      <div className="container mt-2">
        <div className="col-md-6">
          <img src="assets/images/whoWeAre/who_we_are_2.jpg" alt="" srcset="" />
        </div>
        <div className="col-md-6">
          <h2>Who We Are</h2>
          <p>
            Shashwat Structural Solutions Pvt. Ltd. (SSSPL) stands as a beacon
            of excellence in the realm of infrastructure consultancy. With a
            rich history rooted in the expertise formerly held by L&W
            Infrastructures Pvt. Ltd. SSSPL has emerged as a pioneering force in
            the fields of Bridge, Highway & Expressway, Urban Infrastructure,
            and Railway projects. Composed of a dedicated team of over 50
            professionals, SSSPL is equipped with state-of-the-art knowledge,
            aligning with international standards in Detailed Engineering Design
            and offering pre-bid consultancy services for EPC Contractors, HAM,
            and BOT operators.
          </p>
          <Link className="theme-btn-s2" to={"/about"}>
            Explore
          </Link>
        </div>
      </div>
    </>
  );
}

export default WhoweAre